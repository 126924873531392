import api from "../../../../../services/api";
import Sacops from "../../../../../components/jsons/routesTranparency/routesSacopAndLicitacoes";
import NavMenu from "../../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../../components/public/others/NavbarCM";
import Footer from "../../../../../components/public/others/Footer";
import ListSacop from "../../../../../components/public/transparency/sacop/List/padrao/ListAll";
import Loading from "../../../../../components/public/others/Loading";
import Search from "../../../../../components/public/others/ordinances/Utils";
import { TipoPortal } from "../../../../../services/variables";
import SacopListTypeAndSubtype from "../../../../../components/public/transparency/sacop/subTypes/sacopListTypeAndSubtype";
import { useEffect, useMemo, useState } from "react";
import ShowDocuments from "../../../../../components/public/others/ordinances/ShowResult";

export default function ListCardSacop(props) {
  const SacopFilter = props.name;
  const [listResult, setListResult] = useState();
  const [type, setType] = useState();
  const [nameTypeDeclaracao, setNameTypeDeclaracao] = useState(undefined);
  const [route, setRoute] = useState();
  const [removeLoading, setRemoveLoading] = useState(false);

  const formatString = (data, keys) => {
    var resultString = [];

    for (const i in data) {
      const newData = keys
        .map((key) => {
          let value = data[i][key];
          if (key.toLocaleUpperCase().includes("DATA")) {
            // se for data
            const newFormatDate = dateRender(value);
            value = newFormatDate;
          }

          if (key.toLocaleUpperCase().includes("CNPJ")) {
            // se for cnpj

            if (value != undefined || value != null) {
              value = value.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              );
            } else {
              value = "";
            }
          }
          if (
            typeof value != "string" &&
            key.toLocaleUpperCase().includes("VALOR")
          ) {
            //se não for string

            value = moneyFormat(value);
          } else if (typeof value != "string") {
            if (value != undefined || value != null) {
              value = value.toString();
            } else {
              value = "";
            }
          }
          return { [key]: value };
        })
        .reduce((prev, next) => {
          return { ...prev, ...next };
        }, {});
      resultString.push(newData);
    }
    return resultString;
  };
  const moneyFormat = (money) => {
    if (money == "") {
      return money;
    } else {
      var format = { minimumFractionDigits: 2, currency: "BRL" };
      return money.toLocaleString("pt-BR", format);
    }
  };
  const dateRender = (dateReq) => {
    const nameMonth = {
      0: "01",
      1: "02",
      2: "03",
      3: "04",
      4: "05",
      5: "06",
      6: "07",
      7: "08",
      8: "09",
      9: "10",
      10: "11",
      11: "12",
    };
    const date = new Date(dateReq);
    if (dateReq != "") {
      date.setDate(date.getDate() + 1);

      var day = date.getDate();
      if (date.getDate() < 10) {
        var day = "0" + date.getDate();
      }
      const formatDate =
        day + "/" + nameMonth[date.getMonth()] + "/" + date.getFullYear();
      return formatDate;
    } else {
      return "";
    }
  };

  useEffect(() => {
    api
      .get(`/sacop/list/all/${type}`)
      .then((res) => {
        if (res.data.err !== true) {
          const result = res.data.res;
          if (result.length != 0) {
            const newResult = formatString(result, Object.keys(result[0]));

            setListResult(newResult);
          } else {
            setListResult(result);
          }

          // setRemoveLoading(true)
        }
      })
      .catch((err) => {});
  }, [type]);

  useEffect(() => {
    // console.log(SacopFilter)
    for (var prop in Sacops) {
      if (Sacops[prop] != undefined) {
        if (Sacops[prop].TITLE == SacopFilter) {
          setType(Sacops[prop].SELECT);
          setRoute(Sacops[prop].ROUTE);
          if (Sacops[prop]["SELECT-TYPE-DECLARACAO"] !== undefined) {
            setNameTypeDeclaracao(Sacops[prop]["SELECT-TYPE-DECLARACAO"]);
          }
        }
      }
    }
  }, []);

  return (
    <>
      {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
      {TipoPortal == 3 ? (
        <>
          <NavMenu />
        </>
      ) : (
        <>
          <NavMenuCM />
        </>
      )}
      <div className="section-title rotes_pages">
        <h6>{route}</h6>
      </div>
      <div className="section-title-portarias">
        <h4>{SacopFilter}</h4>
      </div>
      {SacopFilter == "Edital e Licitações" && (
        <ShowDocuments
          name={"Plano anual de contratações"}
          select={"Plano anual de contratações"}
        />
      )}
      {nameTypeDeclaracao !== undefined ? (
        <SacopListTypeAndSubtype
          type={nameTypeDeclaracao}
          subType={"Declarações"}
        />
      ) : (
        <></>
      )}
      {listResult != undefined ? (
        <>
          <ListSacop name={SacopFilter} data={listResult} />
        </>
      ) : (
        <>
          <Search />
          {!removeLoading && <Loading />}
        </>
      )}
      <Footer />
    </>
  );
}
