import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import TableFiles from "./ListFilesTypeAndSubTypes";
import Loading from "../../../others/LoadingFull";

export default function SacopListTypeAndSubtype(props) {
  const { type, subType, nameModule } = props;
  const [removeLoading, setRemoveLoading] = useState(false);
  const [resultfile, setFile] = useState([]);
  const [cnpj, setCNPJ] = useState("");

  useEffect(() => {
    if (type != undefined && subType != undefined) {
      const data = { type, subType };
      api
        .post(`/atosAdministrativos/list-by-type-and-subtype`, data)
        .then((res) => {
          if (!res.data.err) {
            const result = res.data;
            if (result.res.length != 0) {
              if (result.res[0]["CNPJ"] != undefined) {
                const stringCnpj = result.res[0]["CNPJ"]
                  .replace("/", "")
                  .replace(".", "")
                  .replace(".", "")
                  .replace("-", "");
                const newResult = formatString(
                  result.res,
                  Object.keys(result.res[0])
                );
                setCNPJ(stringCnpj);
                setFile(newResult);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setRemoveLoading(true);
        });
    }
  }, []);

  const formatString = (data, keys) => {
    const filteredData = data.filter(
      (item) => !item.TITULO.toUpperCase().includes("RECEBIDAS")
    );
    const resultString = filteredData.map((item) => {
      const formattedItem = {};
      keys.forEach((key) => {
        let value = item[key];
        if (key.toUpperCase().includes("DATA")) {
          value = dateRender(value);
        }
        if (key.toUpperCase().includes("CNPJ")) {
          value = value
            ? value.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              )
            : "";
        }
        if (typeof value !== "string" && key.toUpperCase().includes("VALOR")) {
          value = moneyFrom(value);
        } else if (typeof value !== "string") {
          value = value ? value.toString() : "";
        }
        formattedItem[key] = value;
      });
      return formattedItem;
    });
    return resultString;
  };

  function dateRender(dataString) {
    const data = new Date(dataString);
    data.setMinutes(data.getTimezoneOffset());
    return data.toLocaleDateString("pt-BR");
  }

  const moneyFrom = (money) => {
    if (money == "" || typeof money == String) {
    } else {
      var format = { minimumFractionDigits: 2, currency: "BRL" };
      return money.toLocaleString("pt-BR", format);
    }
  };

  return (
    <>
      {!removeLoading && <Loading />}
      {resultfile.length != 0 ? (
        <>
          <TableFiles
            files={resultfile}
            cnpj={cnpj}
            name={"Alterar-Detalhes"}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
